<template>
   <div class="container-fluid">
      <div class="row">
         <div class="col-12">
            <div class="page-title-box">
               <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                     <!-- <li class="breadcrumb-item active">Locations</li> -->
                  </ol>
               </div>
               <h4 class="page-title">Roles</h4>
            </div>
         </div>
      </div>
      <!-- end page title -->

      <div class="row">
         <div class="col-lg-12">
            <div class="card">
               <div class="card-body">
                  <div class="d-flex justify-content-end">
                     <router-link :to="{ name: 'role-create' }">
                        <button type="button" class="
                                 btn btn-sm btn-blue
                                 waves-effect waves-light
                                 mb-2
                              ">
                           <i class="mdi mdi-plus-circle"></i> Add Role
                        </button>
                     </router-link>
                  </div>

                  <place-holder v-if="loading"></place-holder>

                  <div class="table-responsive">
                     <table class="table table-striped dt-responsive w-100 mb-3" id="role-datatable" v-show="!loading">
                        <thead>
                           <tr>
                              <th>No.</th>
                              <th>Name</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="(role, index) in roles" :key="index">
                              <td>{{ ++index }}</td>
                              <td>{{ role.name }}</td>
                              <td>
                                 <router-link class="action-icon" :to="{
                                    name: 'role-update',
                                    params: { id: role.id },
                                 }">
                                    <i class="mdi mdi-square-edit-outline"></i>
                                 </router-link>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <!-- end card -->
         </div>
      </div>
   </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";

export default {
   data() {
      return {
         roles: [],
         loading: false,
         baseUrl: process.env.VUE_APP_BASE_URL,
      };
   },
   methods: {
      async getAllRoles() {
         this.loading = true;
         this.$Progress.start();
         await axios.get(`${this.baseUrl}admin/v1/roles`).then((response) => {
            this.roles = response.data.data;
            this.loading = false;
            this.$Progress.finish();
         });
         $("#role-datatable").DataTable();
      },
      clearFilter() {
         $.fn.dataTable.ext.search.pop();
         $("#role-datatable").DataTable().draw();
      },
   },
   created() {
      this.clearFilter();
      this.getAllRoles();
   },
};
</script>

<style>
.page-item.active .page-link {
   z-index: 3;
   color: #fff;
   background-color: #4a81d4;
   border-color: #4a81d4;
}
</style>